<template>
  <b-modal
    :id="id"
    size="lg"
    title="Báo cáo định kỳ hình hình hoạt động kiểm định"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Tạo báo cáo"
    centered
    @ok="handleCreateTCKDDN02"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-row>
          <b-col
            md="4"
            lg="4"
            xl="4"
            sm="6"
          >
            <b-form-group>
              <label>Ngày bắt đầu <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="startTime"
                rules="required"
                :custom-messages="{required: `Ngày bắt đầu là bắt buộc`,}"
              >
                <date-time-picker
                  v-model="dataInput.startTime"
                  :disabledInput="true"
                  :class="{'is-invalid':errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            lg="4"
            xl="4"
            sm="6"
          >
            <b-form-group>
              <label>Ngày kết thúc <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="endTime"
                rules="required"
                :custom-messages="{required: `Ngày kết thúc là bắt buộc`,}"
              >
                <date-time-picker
                  v-model="dataInput.endTime"
                  :disabledInput="true"
                  :class="{'is-invalid':errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            lg="4"
            xl="4"
            sm="6"
          >
            <b-form-group>
              <label>Số báo cáo <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="reportNumber"
                rules="required"
                :custom-messages="{required: `Số báo cáo là bắt buộc`,}"
              >
                <b-form-input
                  id="InputHelp"
                  v-model="dataInput.reportNumber"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-form-group>
      <label for="InputHelp">Đánh giá công tác kiểm định</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.evaluateAccreditation"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Những vấn đề nảy sinh trong quá trình kiểm định </label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.issueAccreditation"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Đề xuất, kiến nghị đối với cơ quan nhà nước có thẩm quyền để nâng cao hoạt động kiểm định</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.request"
        rows="3"
        no-resize
      />
    </b-form-group>

  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormTextarea, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    DateTimePicker,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        startTime: '',
        endTime: '',
        reportNumber: '',
        evaluateAccreditation: '',
        issueAccreditation: '',
        request: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          startTime: this.dataDetail.startTime,
          endTime: this.dataDetail.endTime,
          reportNumber: this.dataDetail.reportNumber,
          evaluateAccreditation: this.dataDetail.evaluateAccreditation,
          issueAccreditation: this.dataDetail.issueAccreditation,
          request: this.dataDetail.request,
        }
      }
    },
  },
  methods: {

    handleCreateTCKDDN02(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateTCKDDN02', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        startTime: '',
        endTime: '',
        reportNumber: '',
        evaluateAccreditation: '',
        issueAccreditation: '',
        request: '',
      }
    },
  },
}
</script>

<style>

</style>
