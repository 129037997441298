<template>
  <b-modal
    :id="id"
    size="sm"
    title="Thêm file"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleAddFile"
    @hidden="resetState"
  >
    <b-form-group
      class="mt-1"
      label-for="user"
    >
      <b-form-checkbox
        id="checkbox-1"
        v-model="dataInput.isQrCode"
        class="mt-1"
        name="checkbox-1"
      >
        Đính kèm mã QR Code
      </b-form-checkbox>
    </b-form-group>
    <div
      v-if="!dataInput.isQrCode"
      class="modal-accept"
    >
      <b-form-group>
        <label>Chọn file</label>
        <div class="d-flex">
          <b-form-input
            v-model="dataInput.nameFile"
            :disabled="true"
          >
            {{ dataInput.nameFile }}
          </b-form-input>
          <input
            id="file"
            ref="file-second"
            accept="application/pdf"
            type="file"
            class="input-file"
            @change="importFileExcel"
          />
          <b-button
            variant="outline-primary"
            class="btn-button-file ml-1"
            @click="inputFile"
          >
            <div
              class="d-flex"
            >
              <feather-icon
                icon="UploadIcon"
                for="file"
                class="input_label text-primary"
              />
              <label
                class="text-primary ml-50"
              >Tải lên</label>
            </div>
          </b-button>
        </div>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BModal, BFormInput, BForm, BButton, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormInput,
    BButton,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInput: {
        nameFile: null,
        isQrCode: false,
      },
      modelFormData: {},
    }
  },
  methods: {
    async handleAddFile() {
      const formData = new FormData()
      formData.append('IsSecure', this.modelFormData.isSecure)
      formData.append('formFile', this.modelFormData.files)
      formData.append('FileName', this.dataInput.nameFile)
      formData.append('IsQrCode', this.dataInput.isQrCode)
      this.$emit('handleAddFile', formData)
    },
    resetState() {
      this.dataInput = {
        nameFile: null,
        isQrCode: false,
      }
    },
    inputFile() {
      this.$refs['file-second'].click()
    },
    importFileExcel(e) {
      this.dataInput.nameFile = this.$refs['file-second'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-accept {
  .btn-button-file {
    width: 45%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
