<template>
  <div id="filter-custom-report">
    <div class="page-container pb-0 mb-2">
      <div>
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Loại báo cáo</label>
              <v-select
                :reduce="label => label.rType"
                label="rTypeString"
                :options="dataFilterType || []"
                :placeholder="'Loại báo cáo'"
                @input="filterType"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Năm</label>
              <v-select
                :reduce="label => label"
                :options="dataFilterYear || []"
                :placeholder="'Năm'"
                @input="filterYear"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        class="mr-1"
        :arrayExcel="null"
        :hideAdd="false"
        :hideExportFile="false"
        :hideDelete="false"
        :hideDowload="false"
        :hideImportFile="false"
        :showBtnMultiDelete="showBtnMultiDelete"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <template>
        <vue-good-table
          :columns="columns"
          :rows="dataTable || []"
          :sort-options="{
            enabled: false,
          }"
          :select-options="{ enabled: false, selectOnCheckboxOnly: true }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column:Hạn nộp báo cáo -->
            <span v-if="props.column.field === 'dateExpired'">
              <span class="text-nowrap">{{ props.row.dateExpired | formatDateToDDMM }}</span>
            </span>

            <!-- Column:Ngày nộp -->
            <span v-else-if="props.column.field === 'dateSend'">
              <span class="text-nowrap">{{ props.row.dateSend | formatDateToDDMM }}</span>
            </span>

            <!-- Column:Trạng thái -->
            <span v-else-if="props.column.field === 'submitReportStatusString'">
              <b-badge
                :variant="statusVariant(props.row.submitReportStatus)"
                class="border-status"
              >
                <span>{{ props.row.submitReportStatusString }}</span>
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'digitalSignatureStatusString'">
              <b-badge :variant="props.row.digitalSignatureStatus === 'NonDigitalSignature' ? 'light-danger' : 'light-success'">
                {{ props.row.digitalSignatureStatusString }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'actionFunction'">
              <b-dropdown
                v-if="[null].includes(props.row.submitReportStatus) && infoSetting.isReportAuto && infoSetting.isReportFile && infoSetting.isReportForm"
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="infoSetting.isReportAuto"
                  @click="showModalCreateReport(props.row.code, props.row.classificationReportId)"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span>Nộp báo cáo tự động</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="infoSetting.isReportFile"
                  @click="uploadFile(props.row.code, props.row.classificationReportId)"
                >

                  <feather-icon
                    icon="PlusCircleIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span>Nộp báo cáo từ file tải lên</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="['ATVSLDDN03', 'ATVSLDDN01', 'ATVSLDDN02'].includes(props.row.code) && infoSetting.isReportForm"
                  @click="showModalCreateForm(props.row.code, props.row.classificationReportId)"
                >

                  <feather-icon
                    icon="PlusCircleIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span>Nộp báo cáo từ form</span>
                </b-dropdown-item>
              </b-dropdown>
              <input
                ref="upload-file"
                type="file"
                class="d-none"
                accept="application/pdf"
                @change="importFile"
              />
              <feather-icon
                v-if="![null].includes(props.row.submitReportStatus)"
                v-b-tooltip.hover.top="'Xem báo cáo'"
                icon="EyeIcon"
                size="18"
                @click="showFilePdf(props.row)"
              />
              <span v-if="![null].includes(props.row.submitReportStatus)">
                <b-dropdown
                  variant="link"
                  boundary="viewport"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      v-b-tooltip.hover.top="'Tải xuống'"
                      icon="DownloadIcon"
                      size="18"
                      class="text-body"
                    />
                  </template>
                  <!-- xem bao cao  -->
                  <b-dropdown-item
                    v-if="props.row.fileNameWord"
                    @click="downloadFile(props.row.id, 'Word', props.row.name)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Xuất file Word</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="downloadFile(props.row.id, 'PDF', props.row.name)">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Xuất file PDF</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <span
                v-if="['Reject', 'NotSend'].includes(props.row.submitReportStatus)"
                class="moreVertical"
              >
                <b-dropdown
                  variant="link"
                  boundary="viewport"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body"
                    />
                  </template>
                  <!-- xem bao cao  -->
                  <b-dropdown-item
                    v-if="['Reject', 'NotSend'].includes(props.row.submitReportStatus)"
                    @click="showSendReport(props.row.id, props.row.code)"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                    />
                    <span>Nộp báo cáo</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['Reject'].includes(props.row.submitReportStatus)"
                    @click="openModalReject(props.row.id)"
                  >
                    <feather-icon
                      icon="MessageCircleIcon"
                      class="mr-50"
                    />
                    <span>Lý do</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['Reject', 'NotSend'].includes(props.row.submitReportStatus)
                      && ['TCKDDN01', 'TCKDDN02', 'TCKDDN03', 'DVVLDN01', 'DVVLDN02'].includes(props.row.code)"
                    @click="showModalEditReport(props.row.code, props.row.id, props.row.classificationReportId)"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-50"
                    />
                    <span>Chỉnh sửa</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['Reject', 'NotSend'].includes(props.row.submitReportStatus)
                      && ['ATVSLDDN03', 'ATVSLDDN01', 'ATVSLDDN02'].includes(props.row.code) && ['Form'].includes(props.row.submitReportType)"
                    @click="showModalEditReport(props.row.code, props.row.id, props.row.classificationReportId)"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-50"
                    />
                    <span>Chỉnh sửa</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['Reject', 'NotSend'].includes(props.row.submitReportStatus)"
                    @click="deleteItem(props.row.id)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>Xóa</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.submitReportStatus && props.row.submitReportType === 'Auto' "
                    title="Ký số"
                    @click="digitalSigned(props.row)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Ký số</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <feather-icon
                v-if="['Send'].includes(props.row.submitReportStatus)"
                v-b-tooltip.hover.top="'Thu hồi báo cáo'"
                icon="CornerDownLeftIcon"
                size="18"
                @click="openModalRecall(props.row.id)"
              />
            </span>
          </template>
        </vue-good-table>
        <!-- pagination -->
        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </template>
      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!-- modal tạo báo cáo -->
      <modal-create-TCKDDN01
        :id="modalcreateTCKDDN01"
        :type="modalType"
        :dataDetail="dataDetailReport"
        @handleCreateTCKDDN01="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <modal-create-TCKDDN02
        :id="modalcreateTCKDDN02"
        :type="modalType"
        :dataDetail="dataDetailReport"
        @handleCreateTCKDDN02="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <modal-create-TCKDDN03
        :id="modalcreateTCKDDN03"
        :type="modalType"
        :dataDetail="dataDetailReport"
        @handleCreateTCKDDN03="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <modal-create-DVVLDN
        :id="modalcreateDVVLDN"
        :type="modalType"
        :dataDetail="dataDetailReport"
        @handleCreateDVVLDN="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <modal-create-ATVSLDDN03
        :id="modalcreateATVSLDDN03"
        :type="modalType"
        :dataDetail="dataDetailReport"
        @handleCreateATVSLDDN03="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <modal-create-ATVSLDDN0102
        :id="modalcreateATVSLDDN0102"
        :type="modalType"
        :dataDetail="dataDetailReport"
        @handleCreateATVSLDDN0102="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <confirm-modal
        :id="confirmModalAnother"
        :content="modalContentAnother"
        :title="'Bạn có muốn tạo báo cáo'"
        :icon="'FileTextIcon'"
        :color="'text-success'"
        @accept="handleCreate"
      />

      <!-- modal tạo báo cáo -->
      <confirm-modal
        :id="confirmModalForm"
        :content="'Bạn hãy xem xét có tạo báo cáo này hay không'"
        :title="'Bạn có muốn tạo báo cáo'"
        :icon="'FileTextIcon'"
        :color="'text-success'"
        @accept="handleCreateToForm"
      />

      <!-- modal xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfsrc"
      />

      <!-- modal gửi báo cáo -->
      <confirm-modal
        :id="modalAddFile"
        :content="'Hãy xác nhận rằng bạn muốn gửi báo cáo. (Báo cáo được gửi vẫn có thể thu hồi)'"
        :title="'Bạn có muốn gửi báo cáo?'"
        @accept="handleAddFile"
      />

      <!-- modal lý do -->
      <modal-reject
        :id="modalReject"
        :dataDetailReport="dataDetailReport"
      />

      <!-- modal thu hồi -->
      <confirm-modal
        :id="confirmModalRecall"
        :content="modalContentRecall"
        :title="'Bạn có muốn thu hồi báo cáo'"
        @accept="handleRecall"
      />

      <modal-notification
        :id="modalIdNotification"
        title="Dữ liệu báo cáo chưa đúng, vui lòng kiểm tra lại!"
        :message="message"
      />
    </div>
  </div>
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BFormGroup, BBadge, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import DataFilter from '../constants/DataFilter'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ModalCreateTCKDDN01 from './components/ModalCreateTCKDDN01.vue'
import ModalCreateTCKDDN02 from './components/ModalCreateTCKDDN02.vue'
import ModalCreateTCKDDN03 from './components/ModalCreateTCKDDN03.vue'
import ModalCreateDVVLDN from './components/ModalCreateDVVLDN.vue'
import ModalCreateATVSLDDN03 from './components/ModalCreateATVSLDDN03.vue'
import ModalCreateATVSLDDN0102 from './components/ModalCreateATVSLDDN0102.vue'
import ModalSeePdf from './components/ModalSeePdf.vue'
import ModalAddFile from './components/ModalAddFile.vue'
import ModalReject from './components/ModalReject.vue'
import ModalNotification from './components/ModalNotification.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    VSelect,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    'modal-create-TCKDDN01': ModalCreateTCKDDN01,
    'modal-create-TCKDDN02': ModalCreateTCKDDN02,
    'modal-create-TCKDDN03': ModalCreateTCKDDN03,
    'modal-create-DVVLDN': ModalCreateDVVLDN,
    'modal-create-ATVSLDDN03': ModalCreateATVSLDDN03,
    'modal-create-ATVSLDDN0102': ModalCreateATVSLDDN0102,
    ModalSeePdf,
    ModalReject,
    ModalNotification,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      // ==dữ liệu bảng
      dataTable: [],
      columns: [
        {
          label: 'TÊN BÁO CÁO',
          field: 'name',
        },
        {
          label: 'LOẠI BÁO CÁO',
          field: 'rTypeString',
        },
        {
          label: 'Năm',
          field: 'year',
        },
        {
          label: 'NGÀY HẾT HẠN',
          field: 'dateExpired',
        },
        {
          label: 'KÝ SỐ',
          field: 'digitalSignatureStatusString',
        },
        {
          label: 'TRẠNG THÁI',
          field: 'submitReportStatusString',
        },
        {
          label: 'HÌNH THỨC',
          field: 'submitReportTypeString',
        },
        {
          label: 'CHỨC NĂNG',
          width: '175px',
          field: 'actionFunction',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      dataFilterYear: [],
      dataFilterType: DataFilter.dataFilterType,
      totalRecord: 0,
      urlQuery: {
        rType: '',
        year: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      // xử lý xóa
      showBtnMultiDelete: false,
      confirmModalId: 'confirm-modal-delete',
      modalContent: 'Bạn có chắc chắn muốn xóa',
      modalContentAnother: 'Bạn hãy xem xét có tạo báo cáo này hay không',
      modalContentRecall: 'Bạn hãy xem xét có thu hồi báo cáo này hay không',
      modalcreateTCKDDN01: 'confirm-modal-TCKDDN01',
      modalcreateTCKDDN02: 'confirm-modal-TCKDDN02',
      modalcreateTCKDDN03: 'confirm-modal-TCKDDN03',
      modalcreateATVSLDDN03: 'confirm-modal-ATVSLDDN03',
      modalcreateATVSLDDN0102: 'confirm-modal-ATVSLDDN0102',
      modalcreateDVVLDN: 'confirm-modal-DVVLDN',
      confirmModalAnother: 'confirm-modal-another',
      confirmModalRecall: 'confirm-modal-recall',
      modalSeePdf: 'confirm-modal-see-pdf',
      modalIdNotification: 'modalIdNotification',
      modalAddFile: 'confirm-add-file',
      modalReject: 'confirm-reject',
      confirmModalForm: 'confirm-modal-form',
      codeType: null,
      classificationReportId: null,
      reportId: null,
      modeType: '',
      deleteIds: [],
      modalType: '',
      dataDetailReport: {},
      pdfsrc: null,
      infoSetting: {},
      message: '',
    }
  },
  // ======================WATCH=============================
  // ==========================COMPUTED=======================
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
    // ...mapState('worker', ['totalRecord', 'listWorker']),
    statusVariant() {
      const statusColor = {
        NotSend: 'secondary',
        Approve: 'success',
        Reject: 'danger',
        Send: 'warning',
      }
      return status => statusColor[status]
    },
  },
  // ===========================CREATED====================
  created() {
    this.fetchListSubmitReport(this.urlQuery)
    this.getYear()
    this.dataFilterYear = this.yearList
    this.getInfoSetting()
  },
  // =============================METHOD======================
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    // ================loc&Tìm kiếm=============
    // lấy danh sách khi lọc
    async fetchListSubmitReport(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUBMIT_REPORT, {
        params: urlQuery,
      })

      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    async getInfoSetting() {
      const { data } = await axiosApiInstance.get(ConstantsApi.SETTING)
      this.infoSetting = data
    },

    // Mở modal thêm báo cáo form
    showModalCreateForm(code, id) {
      this.modalType = 'add'
      this.codeType = code
      this.classificationReportId = id
      this.$bvModal.show(this.confirmModalForm)
    },

    // Tạo báo cáo dạng từ form
    async handleCreateToForm() {
      const model = {
        reportClassificationId: this.classificationReportId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_FORM, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Tạo thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListSubmitReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal thêm báo cáo
    showModalCreateReport(code, id) {
      this.modalType = 'add'
      this.codeType = code
      this.classificationReportId = id
      switch (this.codeType) {
        case 'TCKDDN01':
          this.$bvModal.show(this.modalcreateTCKDDN01)
          break
        case 'TCKDDN02':
          this.$bvModal.show(this.modalcreateTCKDDN02)
          break
        case 'TCKDDN03':
          this.$bvModal.show(this.modalcreateTCKDDN03)
          break
        case 'DVVLDN01':
        case 'DVVLDN02':
          this.$bvModal.show(this.modalcreateDVVLDN)
          break
        default:
          break
      }
      if (!['TCKDDN01', 'TCKDDN02', 'TCKDDN03', 'DVVLDN01', 'DVVLDN02'].includes(code)) {
        this.$bvModal.show(this.confirmModalAnother)
      }
    },

    // Mở modal lý do trả lại
    async openModalReject(id) {
      this.classificationReportId = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_SUBMIT_REPORT}${this.classificationReportId}`)
      this.dataDetailReport = data
      this.$bvModal.show(this.modalReject)
    },

    // Mở modal sửa báo cáo
    async showModalEditReport(code, reportId, id) {
      this.modalType = 'edit'
      this.codeType = code
      this.classificationReportId = id
      this.reportId = reportId
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_SUBMIT_REPORT}${this.reportId}`)
      this.dataDetailReport = data
      switch (this.codeType) {
        case 'TCKDDN01':
          this.$bvModal.show(this.modalcreateTCKDDN01)
          break
        case 'TCKDDN02':
          this.$bvModal.show(this.modalcreateTCKDDN02)
          break
        case 'TCKDDN03':
          this.$bvModal.show(this.modalcreateTCKDDN03)
          break
        case 'ATVSLDDN03':
          this.$bvModal.show(this.modalcreateATVSLDDN03)
          break
        case 'ATVSLDDN02':
          this.$bvModal.show(this.modalcreateATVSLDDN0102)
          break
        case 'ATVSLDDN01':
          this.$bvModal.show(this.modalcreateATVSLDDN0102)
          break
        case 'DVVLDN01':
        case 'DVVLDN02':
          this.$bvModal.show(this.modalcreateDVVLDN)
          break
        default:
          break
      }
    },

    async handleCreateAnother() {
      const model = {
        reportClassificationId: this.classificationReportId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Tạo thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListSubmitReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async handleEditTypeForm(val) {
      if (['ATVSLDDN01', 'ATVSLDDN02'].includes(this.codeType)) {
        const res = await axiosApiInstance.get(ConstantsApi.CHECK_SUBMIT_REPORT, { params: { submitReportId: this.reportId } })
        if (res.data) {
          this.message = res.data.replace(/\n/g, '<br />')
          this.$bvModal.show(this.modalIdNotification)
          return
        }
      }

      if (this.codeType === 'TCKDDN01') {
        const payload = {
          ...val,
          id: this.reportId,
          reportClassificationId: this.classificationReportId,
          startYear: parseInt(val.startYear, 10),
          endYear: parseInt(val.endYear, 10),
        }

        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_SUBMIT_REPORT, payload).then(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalcreateTCKDDN01)
          this.fetchListSubmitReport(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else {
        const payload = {
          ...val,
          id: this.reportId,
          reportClassificationId: this.classificationReportId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_SUBMIT_REPORT, payload).then(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          switch (this.codeType) {
            case 'TCKDDN01':
              this.$bvModal.hide(this.modalcreateTCKDDN01)
              break
            case 'TCKDDN02':
              this.$bvModal.hide(this.modalcreateTCKDDN02)
              break
            case 'TCKDDN03':
              this.$bvModal.hide(this.modalcreateTCKDDN03)
              break
            case 'ATVSLDDN03':
              this.$bvModal.hide(this.modalcreateATVSLDDN03)
              break
            case 'ATVSLDDN02':
              this.$bvModal.hide(this.modalcreateATVSLDDN0102)
              break
            case 'ATVSLDDN01':
              this.$bvModal.hide(this.modalcreateATVSLDDN0102)
              break
            case 'DVVLDN01':
            case 'DVVLDN02':
              this.$bvModal.hide(this.modalcreateDVVLDN)
              break
            default:
              break
          }
          this.fetchListSubmitReport(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    async handleCreateForm(val) {
      if (this.codeType === 'TCKDDN01') {
        const payload = {
          ...val,
          reportClassificationId: this.classificationReportId,
          startYear: parseInt(val.startYear, 10),
          endYear: parseInt(val.endYear, 10),
        }

        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT, payload).then(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Tạo thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalcreateTCKDDN01)
          this.fetchListSubmitReport(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else {
        const payload = {
          ...val,
          reportClassificationId: this.classificationReportId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT, payload).then(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Tạo thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          switch (this.codeType) {
            case 'TCKDDN01':
              this.$bvModal.hide(this.modalcreateTCKDDN01)
              break
            case 'TCKDDN02':
              this.$bvModal.hide(this.modalcreateTCKDDN02)
              break
            case 'TCKDDN03':
              this.$bvModal.hide(this.modalcreateTCKDDN03)
              break
            case 'DVVLDN01':
            case 'DVVLDN02':
              this.$bvModal.hide(this.modalcreateDVVLDN)
              break
            default:
              break
          }
          this.fetchListSubmitReport(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    async handleTakeTypeForm(val) {
      if (this.modalType === 'edit') {
        await this.handleEditTypeForm(val)
      }
      if (this.modalType === 'add') {
        await this.handleCreateForm(val)
      }
    },

    // Tạo Báo cáo
    async handleCreate(val) {
      if (!['TCKDDN01', 'TCKDDN02', 'TCKDDN03', 'DVVLDN01', 'DVVLDN02', 'ATVSLDDN03', 'ATVSLDDN01', 'ATVSLDDN02'].includes(this.codeType)) {
        await this.handleCreateAnother()
      } else {
        await this.handleTakeTypeForm(val)
      }
    },

    // Tải file báo cáo
    downloadFile(id, val, name) {
      const formatType = val
      axiosApiInstance({
        url: '/SubmitReport/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { submitReportId: id, reportFormat: formatType },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const nameFile = formatType === 'Word' ? `${name}.docx` : `${name}.pdf`
        link.setAttribute('download', nameFile) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // XEM file báo cáo
    showFilePdf(val) {
      // this.pdfsrc = this.$serverfile + val.filePath
      axiosApiInstance({
        url: '/SubmitReport/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { submitReportId: val.id, reportFormat: 'PDF' },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfsrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal gửi báo cáo
    async showSendReport(id, code) {
      if (['ATVSLDDN01', 'ATVSLDDN02'].includes(code)) {
        const res = await axiosApiInstance.get(ConstantsApi.CHECK_SUBMIT_REPORT, { params: { submitReportId: id } })
        if (res.data) {
          this.message = res.data.replace(/\n/g, '<br />')
          this.$bvModal.show(this.modalIdNotification)
          return
        }
      }
      this.reportId = id
      this.$bvModal.show(this.modalAddFile)
    },

    async handleAddFile() {
      const val = new FormData()
      val.append('Id', this.reportId)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.SEND_SUBMIT_REPORT, val).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Gửi thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fetchListSubmitReport(this.urlQuery)
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Gửi không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },

    // -----phần phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListSubmitReport(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 2
        this.urlQuery.key = val
        this.fetchListSubmitReport(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListSubmitReport(this.urlQuery)
      }
    },

    filterType(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.rType = val
        this.fetchListSubmitReport(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.rType = ''
        this.fetchListSubmitReport(this.urlQuery)
      }
    },

    filterYear(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchListSubmitReport(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = ''
        this.fetchListSubmitReport(this.urlQuery)
      }
    },

    // =====================Xóa=====================
    // Mở modal xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // mở modal Thu hồi báo cáo
    openModalRecall(id) {
      this.reportId = id
      this.$bvModal.show(this.confirmModalRecall)
    },

    // Thu hồi báo cáo
    async handleRecall() {
      const model = {
        id: this.reportId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REVOKE_SUBMIT_REPORT, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thu hồi thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListSubmitReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thu hồi không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thực hiện delete
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_SUBMIT_REPORT, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListSubmitReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    digitalSigned(val) {
      val.fileUrl = this.$serverfile + val.filePath
      val.fileUploadHandler = `${this.$serverfile}home/sign-report/${val.id}`
      const prms = {}
      prms.FileUploadHandler = val.fileUploadHandler
      prms.SessionId = ''
      prms.FileName = val.fileUrl
      prms.MetaData = [val]
      // eslint-disable-next-line no-undef
      vgca_sign_file(JSON.stringify(prms), this.signCallback)
    },
    signCallback(val) {
      val = JSON.parse(val)
      if (val.Status > 0) {
        this.$root.$bvToast.toast(val.Message, {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        this.$root.$bvToast.toast('Ký số thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }
    },

    uploadFile(code, classificationReportId) {
      this.classificationReportId = classificationReportId
      this.$refs['upload-file'].click()
    },

    async importFile(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      const fileName = this.$refs['upload-file'].files[0].name
      const formData = new FormData()
      formData.append('IsSecure', false)
      formData.append('formFile', file)
      formData.append('FileName', fileName)
      formData.append('ReportClassificationId', this.classificationReportId)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.SUBMIT_REPORT, formData)
        .then(() => {
          this.fetchListSubmitReport(this.urlQuery)
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Tạo báo cáo thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
        .catch(() => {
          this.fetchListSubmitReport(this.urlQuery)
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Tạo báo cáo không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
    },
  },
}
</script>

<style lang="scss">
#filter-custom-report{
  .border-status {
    border-radius: 4px;
  }
  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }
  .moreVertical{
    button{
      padding: 0 !important;
    }
  }
}

</style>
