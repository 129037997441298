<template>
  <b-modal
    :id="id"
    ok-variant="outline-primary"
    ok-title="Hủy bỏ"
    header-text-variant="light"
    centered
    size="lg"
    scrollable
    ok-only
    header-bg-variant="danger"
    :title="title"
    hide-header-close
  >
    <div v-html="message" />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}

</script>
<style>
#modalIdNotification___BV_modal_title_ {
  color: white;
}

#modalIdNotification___BV_modal_body_ {
  height: 80vh;
}
</style>
