<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="handleCreateATVSLDDN03($event)"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="rule">
        <InformationReport
          v-if="isRendered('general')"
          id="general"
          refs="information"
          :type="type"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{active:activeTab==='general'}"
        />
        <CriteriaReport
          v-if="isRendered('report')"
          id="report"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='report'}"
        />
        <TableEvaluateFirst
          v-if="isRendered('evaluate')"
          id="report"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='evaluate'}"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'

export default {
  components: {
    BModal,
    ValidationObserver,
    MyTabs,
    InformationReport: () => import('./tags-ATVSLDDN03/InformationReport.vue'),
    CriteriaReport: () => import('./tags-ATVSLDDN03/CriteriaReport.vue'),
    TableEvaluateFirst: () => import('./tags-ATVSLDDN03/TableEvaluateFirst.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      collectReportId: null,
      activeTab: null,
      listTabs: [
        {
          key: 'general',
          title: 'Thông tin báo cáo',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'report',
          title: 'Tiêu chí báo cáo',
          icon: 'FileIcon',
          isRendered: false,
        },
        {
          key: 'evaluate',
          title: 'Đánh giá lần đầu',
          icon: 'CheckSquareIcon',
          isRendered: false,
        },
      ],
      dataGeneral: {
        isWorkAbove30h: false,
        rentOSHService: false,
        oshService: false,
        rentHealthService: false,
        healthService: false,
        evaluationPeriod: false,
        evaluationEffective: false,
        elementIdenty: '',
        elementImprove: '',
        isSupervise: false,
      },
      submitReportId: '',
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataGeneral = {
          isWorkAbove30h: this.dataDetail.isWorkAbove30h,
          rentOSHService: this.dataDetail.rentOSHService,
          oshService: this.dataDetail.oshService,
          rentHealthService: this.dataDetail.rentHealthService,
          healthService: this.dataDetail.healthService,
          evaluationPeriod: this.dataDetail.evaluationPeriod,
          evaluationEffective: this.dataDetail.evaluationEffective,
          elementIdenty: this.dataDetail.elementIdenty,
          elementImprove: this.dataDetail.elementImprove,
          isSupervise: this.dataDetail.isSupervise,
        }
        this.submitReportId = this.dataDetail.id
      }
    },

    activeTab(val) {
      if (val === null) {
        this.activeTab = 'general'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'general'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    handleCreateATVSLDDN03(bvModalEvt) {
      const model = {
        ...this.dataGeneral,
        elementIdenty: Number(this.dataGeneral.elementIdenty),
        elementImprove: Number(this.dataGeneral.elementImprove),
      }
      this.$emit('handleCreateATVSLDDN03', model)
    },

    resetData() {
      this.activeTab = null
      this.dataGeneral = {
        isWorkAbove30h: false,
        rentOSHService: false,
        oSHService: false,
        rentHealthService: false,
        healthService: false,
        evaluationPeriod: false,
        evaluationEffective: false,
        elementIdenty: '',
        elementImprove: '',
        isSupervise: false,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
