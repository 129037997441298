<template>
  <b-modal
    :id="id"
    size="lg"
    title="Báo cáo định kỳ hoạt động huấn luyện an toàn, vệ sinh lao động"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Tạo báo cáo"
    centered
    @ok="handleCreateTCKDDN03"
    @hidden="resetState"
  >
    <b-form-group>
      <label for="InputHelp">Việc duy trì điều kiện về cơ sở vật chất, đội ngũ người huấn luyện đáp ứng yêu cầu hoạt động huấn luyện</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.conditionMaterialFacilities"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Đề xuất, kiến nghị</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.request"
        rows="3"
        no-resize
      />
    </b-form-group>

  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormTextarea, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInput: {
        conditionMaterialFacilities: '',
        request: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          conditionMaterialFacilities: this.dataDetail.conditionMaterialFacilities,
          request: this.dataDetail.request,
        }
      }
    },
  },
  methods: {

    handleCreateTCKDDN03() {
      this.$emit('handleCreateTCKDDN03', this.dataInput)
    },
    resetState() {
      this.dataInput = {
        conditionMaterialFacilities: '',
        request: '',
      }
    },
  },
}
</script>

<style>

</style>
