<template>
  <div>
    <b-modal
      :id="id"
      size="lg"
      title="Lý do"
      cancel-variant="outline-primary"
      centered
      hide-footer
    >
      <span>{{ dataInput.reasonReject }}</span>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    dataDetailReport: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      dataInput: {
        reasonReject: '',
      },
    }
  },
  watch: {
    dataDetailReport(val) {
      if (val) {
        this.dataInput = {
          reasonReject: this.dataDetailReport.reasonReject,
        }
      }
    },
  },
}
</script>
