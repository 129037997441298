export default {
  dataFilterYear: [
    {
      year: 2010,
    },
    {
      year: 2011,
    },
    {
      year: 2012,
    },
    {
      year: 2013,
    },
    {
      year: 2014,
    },
    {
      year: 2015,
    },
    {
      year: 2016,
    },
    {
      year: 2017,
    },
    {
      year: 2018,
    },
    {
      year: 2019,
    },
    {
      year: 2020,
    },
    {
      year: 2021,
    },
  ],
  dataFilterType: [
    {
      rType: 'Worker',
      rTypeString: 'Người lao động',
    },
    {
      rType: 'OSH',
      rTypeString: 'An toàn vệ sinh lao động',
    },
    {
      rType: 'JobService',
      rTypeString: 'Dịch vụ việc làm',
    },
  ],
}
