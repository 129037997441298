<template>
  <b-modal
    :id="id"
    size="lg"
    title="Báo cáo tình hình hoạt động dịch vụ việc làm"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Tạo báo cáo"
    centered
    @ok="handleCreateDVVLDN"
    @hidden="resetState"
  >
    <b-form-group>
      <label for="InputHelp">Thu thập, phân tích, dự báo và cung ứng thông tin thị trường lao động</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.collectAndAnaysisJob"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Thực hiện các nhiệm vụ khác</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.anotherMission"
        rows="3"
        no-resize
      />
    </b-form-group>

    <div class="d-flex">
      <feather-icon
        icon="StarIcon"
        size="18"
      />
      <h3 class="ml-1 mb-1">
        Đánh giá
      </h3>
    </div>

    <b-form-group>
      <label for="InputHelp">Những mặt được</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.positiveEvaluate"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Hạn chế</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.negativeEvaluate"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Đề xuất, kiến nghị</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.request"
        rows="3"
        no-resize
      />
    </b-form-group>

  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormTextarea, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        collectAndAnaysisJob: '',
        anotherMission: '',
        positiveEvaluate: '',
        negativeEvaluate: '',
        request: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          collectAndAnaysisJob: this.dataDetail.collectAndAnaysisJob,
          anotherMission: this.dataDetail.anotherMission,
          positiveEvaluate: this.dataDetail.positiveEvaluate,
          negativeEvaluate: this.dataDetail.negativeEvaluate,
          request: this.dataDetail.request,
        }
      }
    },
  },
  methods: {

    handleCreateDVVLDN() {
      this.$emit('handleCreateDVVLDN', this.dataInput)
    },
    resetState() {
      this.dataInput = {
        conditionMaterialFacilities: '',
        request: '',
      }
    },
  },
}
</script>

<style>

</style>
