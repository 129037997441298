<template>
  <b-modal
    :id="id"
    size="lg"
    title="Báo cáo tình hình hoạt động kiểm định kỹ thuật an toàn lao động doanh nghiệp"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Tạo báo cáo"
    centered
    @ok="handleCreateTCKDDN01"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-row>
          <b-col
            md="6"
            lg="6"
            xl="6"
            sm="6"
          >
            <b-form-group>
              <label>Năm bắt đầu <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="startYear"
                rules="required"
                :custom-messages="{required: `Năm bắt đầu là bắt buộc`,}"
              >
                <b-form-input
                  id="InputHelp"
                  v-model="dataInput.startYear"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            lg="6"
            xl="6"
            sm="6"
          >
            <b-form-group>
              <label>Năm kết thúc <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="endYear"
                rules="required"
                :custom-messages="{required: `Năm kết thúc là bắt buộc`,}"
              >
                <b-form-input
                  id="InputHelp"
                  v-model="dataInput.endYear"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-form-group>
      <label for="InputHelp">Tình hình thực hiện quy định pháp luật về trách nhiệm của tổ chức hoạt động kiểm định kỹ thuật an toàn lao động</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.lawEnforcement"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Những thay đổi về năng lực kiểm định của tổ chức</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.changeAbility"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Việc thực hiện đề nghị, kiến nghị của đoàn thanh tra</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.requestEnforcement"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Các hình thức kỷ luật, khen thưởng</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.rewardAndDiscipline"
        rows="3"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Kiến nghị</label>
      <b-form-textarea
        id="InputHelp"
        v-model="dataInput.request"
        rows="3"
        no-resize
      />
    </b-form-group>

  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormTextarea, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        startYear: 0,
        endYear: 0,
        lawEnforcement: '',
        changeAbility: '',
        requestEnforcement: '',
        rewardAndDiscipline: '',
        request: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          startYear: this.dataDetail.startYear,
          endYear: this.dataDetail.endYear,
          lawEnforcement: this.dataDetail.lawEnforcement,
          changeAbility: this.dataDetail.changeAbility,
          requestEnforcement: this.dataDetail.requestEnforcement,
          rewardAndDiscipline: this.dataDetail.rewardAndDiscipline,
          request: this.dataDetail.request,
        }
      }
    },
  },
  methods: {

    handleCreateTCKDDN01(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateTCKDDN01', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        startYear: '',
        endYear: '',
        lawEnforcement: '',
        changeAbility: '',
        requestEnforcement: '',
        rewardAndDiscipline: '',
        request: '',
      }
    },
  },
}
</script>

<style>

</style>
